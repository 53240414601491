import React from 'react'
import BlogSectionGrid from './BlogSectionGrid/BlogSectionGrid'

const BlogGrid = () => {
  return (
    <>
     <BlogSectionGrid/>  
    

    </>
  )
}

export default BlogGrid
